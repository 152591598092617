<template>
  <div
    class="doctor-detail-container"
    v-loading="isloading || isuploadingSingleField"
    :element-loading-text="loadingText || '数据上传中'"
  >
    <item-scroll ref="itemScroll">
      <div class="doctor-detail-main">
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.name">*</span>姓名</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              v-model.trim="doctorNameAndHospital"
              placeholder="请输入专家姓名"
              :disabled="true"
            ></el-input>
            <!-- <div
              class="docterdetail_button"
              v-if="status == 3 && modifyShow['name'].button"
              @click="modify('name')"
            >
              修改
            </div> -->
          </div>
        </div>
        <!-- <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.hospital">*</span>医院</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('hospital')"
              v-model.trim="doctorInfo.hospital"
              placeholder="请输入医院名称"
              :disabled="!modifyShow['hospital'].content"
            ></el-input>
            <div class="docterdetail_button" v-if="modifyShow['hospital'].button" @click="modify('hospital')">
              修改
            </div>
          </div>
        </div> -->

        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.phone">*</span>手机号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @input="inputField('phone')"
              v-model.trim="doctorInfo.phone"
              placeholder="请输入手机号"
              :disabled="!modifyShow['phone'].content"
            ></el-input>
            <div class="docterdetail_button" v-if="!modifyShow['phone'].isUpdate&&modifyShow['phone'].button" @click="modify('phone')">修改</div>
          </div>
          <div class="update_button_group" v-if="modifyShow['phone'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('phone')" v-if="modifyShow['phone'].cancleButton">取消修改</div>
            <div class="update_button" @click="doCommitSingle('phone')">保存</div>
          </div>
        </div>
        <div class="doctor-detail-block" v-if="!modifyShow.provinceAndCity.content">
          <div class="doctor-detail-key" >
            <span v-show="!(doctorInfo.province && doctorInfo.city)">*</span>省份城市</div>
          <div class="global_text_input doctor-detail-value">
            <div v-if="doctorInfo.province && doctorInfo.city">
              {{ doctorInfo.province }}{{ doctorInfo.city }}
            </div>
            <div v-else>待完善</div>
            <div class="docterdetail_button" v-if="!modifyShow['provinceAndCity'].isUpdate&&modifyShow['provinceAndCity'].button" @click="modify('provinceAndCity')">修改</div>
          </div>
        </div>
        <div v-if="modifyShow.provinceAndCity.content">
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.province">*</span>省份（示例：湖北）
            </div>
            <div
              class="global_text_input global_text_select doctor-detail-value"
            >
              <el-select
                v-model="doctorInfo.province"
                placeholder="请选择医生省份"
                @change="changeProvince()"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.city">*</span>城市（示例：武汉）
            </div>
            <div
              class="global_text_input global_text_select doctor-detail-value"
            >
              <el-select
                v-model="doctorInfo.city"
                placeholder="请选择医生城市"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['provinceAndCity'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('provinceAndCity')">
              取消修改
            </div>
            <div class="update_button" @click="doCommitSingle('provinceAndCity')">保存</div>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.id_card_number">*</span>身份证号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                @input="inputField('id_card_number')"
              v-model.trim="doctorInfo.id_card_number"
              placeholder="请输入身份证号"
              :disabled="!modifyShow['id_card_number'].content"
            ></el-input>
            <div class="docterdetail_button" v-if="!modifyShow['id_card_number'].isUpdate&&modifyShow['id_card_number'].button" @click="modify('id_card_number')">
              修改
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['id_card_number'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('id_card_number')" v-if="modifyShow['id_card_number'].cancleButton">取消修改</div>
            <div class="update_button" @click="doCommitSingle('id_card_number')">保存</div>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!(doctorInfo.id_card_front && doctorInfo.id_card_reverse)">*</span>身份证照片</div>
          <div class="global_text_input doctor-detail-value" v-if="!modifyShow.idCardImg.content">
            <div>
              {{
                doctorInfo.id_card_front && doctorInfo.id_card_reverse
                  ? "已有"
                  : "待完善"
              }}
            </div>
            <div class="docterdetail_button" v-if="!modifyShow['idCardImg'].isUpdate&&modifyShow['idCardImg'].button" @click="modify('idCardImg')">
              修改
            </div>
          </div>
          <div v-if="modifyShow.idCardImg.content">
            <div class="doctor-detail-value global_container_center">
              <div
                class="doctor-detail-image-block"
                @click="selectFile('id_card_front')"
              >
                <img
                  :src="doctorInfo.id_card_front"
                  v-if="doctorInfo.id_card_front && !upload.id_card_front"
                  alt=""
                />
                <div class="doctor-detail-image-select" v-else>
                  <i
                    v-show="!doctorInfo.id_card_front"
                    class="el-icon-plus"
                  ></i>
                  <span>{{
                    getShowName("id_card_front", "上传身份证正面")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="doctor-detail-value global_container_center">
              <div
                class="doctor-detail-image-block"
                @click="selectFile('id_card_reverse')"
              >
                <img
                  :src="doctorInfo.id_card_reverse"
                  v-if="doctorInfo.id_card_reverse && !upload.id_card_reverse"
                  alt=""
                />
                <div class="doctor-detail-image-select" v-else>
                  <i
                    v-show="!doctorInfo.id_card_reverse"
                    class="el-icon-plus"
                  ></i>
                  <span>{{
                    getShowName("id_card_reverse", "上传身份证反面")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="update_button_group" v-if="modifyShow['idCardImg'].isUpdate">
              <div class="update_button cancle_button" @click="cancleUpdate('idCardImg')" v-if="modifyShow['idCardImg'].cancleButton">取消修改</div>
              <div class="update_button" @click="doCommitSingle('idCardImg')">保存</div>
            </div>
          </div>
        </div>
        <!-- 银行账号 -->
        <div class="doctor-detail-block" v-if="!modifyShow.bank.content">
          <div class="doctor-detail-key" >
            <span v-show="
                !(doctorInfo.bank_name &&
                doctorInfo.bank_province &&
                doctorInfo.bank_city &&
                doctorInfo.bank_name_point &&
                doctorInfo.bank_number)">*</span>银行账号</div>
          <div class="global_text_input doctor-detail-value">
            <div
              v-if="
                doctorInfo.bank_name &&
                doctorInfo.bank_province &&
                doctorInfo.bank_city &&
                doctorInfo.bank_name_point &&
                doctorInfo.bank_number
              "
            >
              {{ doctorInfo.bank_name }}{{ doctorInfo.bank_number }}
            </div>
            <div v-else>待完善</div>
            <div class="docterdetail_button" v-if="!modifyShow['bank'].isUpdate&&modifyShow['bank'].button" @click="modify('bank')">修改</div>
          </div>
        </div>
        <div v-if="modifyShow.bank.content">
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.bank_name">*</span>银行（示例：交通银行）
            </div>
            <div class="global_text_input doctor-detail-value">
              <el-input
                @input="inputField('bank_name')"
                v-model.trim="doctorInfo.bank_name"
                placeholder="请输入银行"
              ></el-input>
            </div>
          </div>
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.bank_province">*</span>开户行所在省份（示例：湖北）
            </div>
            <div
              class="global_text_input global_text_select doctor-detail-value"
            >
              <el-select
                v-model="doctorInfo.bank_province"
                placeholder="请选择开户行所在省份"
                @change="changeProvince('bank')"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.bank_city">*</span>开户行所在城市（示例：武汉）
            </div>
            <div
              class="global_text_input global_text_select doctor-detail-value"
            >
              <el-select
                v-model="doctorInfo.bank_city"
                placeholder="请选择开户行所在城市"
              >
                <el-option
                  v-for="(item, index) in bankCitys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="doctor-detail-block">
            <div class="doctor-detail-key">
              <span v-show="!doctorInfo.bank_name_point">*</span>开户银行（xx支行/xx营业部）
            </div>
            <div class="global_text_input doctor-detail-value">
              <el-input
                  @input="inputField('bank_name_point')"
                v-model.trim="doctorInfo.bank_name_point"
                placeholder="请输入开户银行"
              ></el-input>
            </div>
          </div>
          <div class="doctor-detail-block">
            <div class="doctor-detail-key"><span v-show="!doctorInfo.bank_number">*</span>银行账号</div>
            <div class="global_text_input doctor-detail-value">
              <el-input
                @input="inputField('bank_number')"
                v-model.trim="doctorInfo.bank_number"
                placeholder="请输入银行账号"
              ></el-input>
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['bank'].isUpdate">
            <div class="update_button cancle_button"
              v-if="modifyShow['bank'].cancleButton"
              @click="cancleUpdate('bank')">
              取消修改
            </div>
            <div class="update_button" @click="doCommitSingle('bank')">保存</div>
          </div>
        </div>

        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.department">*</span>专家科室</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @input="inputField('department')"
              @change="changeExamineStatus()"
              v-model.trim="doctorInfo.department"
              placeholder="请输入专家科室"
              :disabled="!modifyShow['department'].content"
            ></el-input>
            <div class="docterdetail_button" v-if="!modifyShow['department'].isUpdate&&modifyShow['department'].button" @click="modify('department')">
              修改
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['department'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('department')" v-if="modifyShow['department'].cancleButton">取消修改</div>
            <div class="update_button" @click="doCommitSingle('department')">保存</div>
          </div>
        </div>

        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.job_name">*</span>专家职称（示例：住院/主治/副主任/主任医师）
          </div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
              v-model="doctorInfo.job_name"
              placeholder="请选择专家职称"
              @change="changeExamineStatus()"
              :disabled="!modifyShow['job_name'].content"
            >
              <el-option
                v-for="(item, index) in config.titles"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="docterdetail_button" v-if="!modifyShow['job_name'].isUpdate&&modifyShow['job_name'].button" @click="modify('job_name')">
              修改
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['job_name'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('job_name')" v-if="modifyShow['job_name'].cancleButton">取消修改</div>
            <div class="update_button" @click="doCommitSingle('job_name')">保存</div>
          </div>
        </div>
        <!-- 专家职称证明 -->

        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span  v-show="!Boolean(
                  this.doctorInfo.doctor_work_id_card ||
                    (this.doctorInfo.professional_person &&
                      this.doctorInfo.professional_panoramic)
                )">*</span>
                专家职称证明
                <span style="color: #6f6f6f;" v-if="modifyShow.professionalProve.content">（可上传1-3张，请务必包含专家姓名、医院、科室、职称信息，否则无法审核通过）</span>
                <p style="color: #f00;" v-if="doctorInfo.examine_status === 0">人工审核中</p>
                <p style="color: #f00;" v-if="doctorInfo.examine_status === 2">人工审核反馈：{{ doctorInfo.reason }}</p>
          </div>
          <div class="global_text_input doctor-detail-value" v-if="!modifyShow.professionalProve.content">
            <div
              v-if="
                !Boolean(
                  this.doctorInfo.doctor_work_id_card ||
                    (this.doctorInfo.professional_person &&
                      this.doctorInfo.professional_panoramic)
                )
              "
            >
              待完善
            </div>
            <div v-else>已有</div>
            <div
              class="docterdetail_button"
              v-if="!modifyShow['professionalProve'].isUpdate&&modifyShow['professionalProve'].button"
              @click="modify('professionalProve')"
            >
              修改
            </div>
          </div>
        </div>

        <div v-if="modifyShow.professionalProve.content">
          <div class="doctor-detail-block">
            <!-- <div class="doctor-detail-key">
              <span>*</span>（以下信息二选一必填）
            </div> -->
            <div class="global_text_input doctor-detail-value">
              <el-radio
                v-model.number="doctorInfo.work_type"
                :label="index"
                v-for="(item, index) in config.workType"
                :key="index"
                @change="changeWorkType"
                >{{ item }}</el-radio
              >
            </div>
          </div>
          <div class="doctor-detail-block" v-if="doctorInfo.work_type == 0">
            <div class="doctor-detail-key">医生ID卡</div>
            <div class="doctor-detail-value global_container_center">
              <div
                class="doctor-detail-image-block"
                @click="selectFile('doctor_work_id_card')"
              >
                <img
                  :src="doctorInfo.doctor_work_id_card"
                  v-if="
                    doctorInfo.doctor_work_id_card &&
                    !upload.doctor_work_id_card
                  "
                  alt=""
                />
                <div class="doctor-detail-image-select" v-else>
                  <i
                    v-show="!doctorInfo.doctor_work_id_card"
                    class="el-icon-plus"
                  ></i>
                  <span>{{
                    getShowName("doctor_work_id_card", "上传医生ID卡")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
            <div class="doctor-detail-key">官网全景图（需截到官网网址链接）</div>
            <div class="doctor-detail-value global_container_center">
              <div
                class="doctor-detail-image-block"
                @click="selectFile('professional_panoramic')"
              >
                <img
                  :src="doctorInfo.professional_panoramic"
                  v-if="
                    doctorInfo.professional_panoramic &&
                    !upload.professional_panoramic
                  "
                  alt=""
                />
                <div class="doctor-detail-image-select" v-else>
                  <i
                    v-show="!doctorInfo.professional_panoramic"
                    class="el-icon-plus"
                  ></i>
                  <span>{{
                    getShowName("professional_panoramic", "上传官网全景图")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
            <div class="doctor-detail-key">官网医生身份信息截图页</div>
            <div class="doctor-detail-value global_container_center">
              <div
                class="doctor-detail-image-block"
                @click="selectFile('professional_person')"
              >
                <img
                  :src="doctorInfo.professional_person"
                  v-if="
                    doctorInfo.professional_person &&
                    !upload.professional_person
                  "
                  alt=""
                />
                <div class="doctor-detail-image-select" v-else>
                  <i
                    v-show="!doctorInfo.professional_person"
                    class="el-icon-plus"
                  ></i>
                  <span>{{
                    getShowName(
                      "professional_person",
                      "上传官网医生身份信息截图页"
                    )
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="update_button_group" v-if="modifyShow['professionalProve'].isUpdate">
            <div class="update_button cancle_button" @click="cancleUpdate('professionalProve')" v-if="modifyShow['professionalProve'].cancleButton">取消修改</div>
            <div class="update_button" @click="doCommitSingle('professionalProve')">保存</div>
          </div>
        </div>

        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.professional_cover">*</span>简历图片<span style="color: red"
              >(请上传近期照片，贴合白色轮廓)</span
            >
          </div>
          <div
            class="professional_cover_container global_text_select doctor-detail-value"
          >
            <div
              class="professional_cover"
              @click="selectFile('professional_cover')"
            >
              <img
                :src="doctorInfo.professional_cover"
                v-if="doctorInfo.professional_cover"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/images/icon/resume_img_have.png')"
                alt=""
              />
            </div>
            <!-- <div class="professional_cover_tips">
              上传近期照片<br>
              贴合白色轮廓
            </div> -->
          </div>
        </div>
        <div class="doctor-detail-block">
          <el-button type="primary" @click.native="doCommit" round>
            <span>提交信息</span>
          </el-button>
        </div>
      </div>
    </item-scroll>

    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="config.cropper.cropWidth"
      :CropHeight="config.cropper.cropHeight"
      :flexScale="config.cropper.flexScale"
      :MaxSize="config.cropper.MaxSize"
      :isShowHeadimgCalibration="config.cropper.isShowHeadimgCalibration"
      v-if="cropperManager.url"
    ></image-cropper>
    <input
      type="file"
      v-show="false"
      v-if="cropperManager.resetFile"
      @change="changeFile"
      ref="selectfiles"
    />
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import ImageCropper from "@/components/unit/ImageCropper";
import weixin from "@/common/weixin";
export default {
  name: "doctor-detail",
  components: {
    ItemScroll,
    ImageCropper,
  },
  data() {
    return {
      config: {
        titles: ["医师(住院医师)", "主治医师", "副主任医师", "主任医师"], // 职称列表
        citys: [],
        workType: ["医生ID卡", "官网医生身份信息截图页"],
        //modify中的字段包含的医生字段
        modifyField:{
          name: ['name'],
          provinceAndCity: ['province','city'],
          hospital: ['hospital'],
          phone: ['phone'],
          id_card_number: ['id_card_number'],
          department: ['department'],
          job_name: ['job_name'],
          idCardImg:['id_card_front','id_card_reverse'],
          bank:['bank_name','bank_province','bank_city','bank_name_point','bank_number'],
          professionalProve:['work_type','doctor_work_id_card','professional_panoramic','professional_person'],
          professionalCover:['professional_cover']
        },
        cropper: {
          cropWidth: 300,
          cropHeight: 225,
          flexScale: [4, 3],
          MaxSize:2,
          isShowHeadimgCalibration: false,
        },
      },
      upload: {
        phone: 0,
        id_card_number: 0,
        bank_number: 0,
        id_card_front: 0,
        id_card_reverse: 0,
        doctor_work_id_card: 0,
        professional_person: 0,
        professional_panoramic: 0,
        // professional_cover: 0,
      },
      cropperManager: {
        key: "",
        resetFile: true,
        url: "",
      },
      doctorNameAndHospital:"",
      cacheDoctorInfo:{},
      updateDoctorInfo: {},//修改到一半缓存的医生信息
      doctorInfo: {
        // all_doctor_id: "",
        // openid: "",
        // name: "",
        // province: "",
        // city: "",
        // hospital: "",
        // hospital_code: "",
        // department: "",
        // phone: "",
        // id_card_number: "",
        // bank_province: "",
        // bank_city: "",
        // bank_name: "",
        // bank_name_point
        // bank_number: "",
        // id_card_front: "",
        // id_card_reverse: "",
        // meet_count: "",
        // work_type: 0,
        // doctor_work_id_card: "",
        // isspecial: "",
        // professional_title: "",
        // professional_person: "",
        // professional_panoramic: "",
        // job_name: "",
        // professional_cover: "",
      },
      dealWithKey: "",
      curbase64: "",
      resetFile: true,
      changeImg: {},
      backInfo: {},
      isloading: false,
      loadingText:"数据上传中",
      isDirty:false,//是否修改过数据
      isuploadingSingleField: false,
      uploadingSingleFieldErr:false,//修改单个字段的时候是否有报错
      mergeFields: {
        province: "",
        city: "",
        bank_name: "",
        deposit_bank: "",
      },
      commitSingleField:{
        provinceAndCity:"医生省份城市修改失败，请检查",
        phone:"手机号修改失败，请检查",
        id_card_number: "身份证号码修改失败，请检查",
        idCardImg: "身份证照片修改失败，请检查",
        bank: "银行信息修改失败，请检查",
        department: "科室修改失败，请检查",
        job_name: "专家职称修改失败，请检查",
        professionalProve: "专家职称证明修改失败，请检查",
        professionalCover: "简历图片修改失败，请检查",
      },
      modifyShow: {
        name: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true//是否显示取消修改按钮
        },
        hospital: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true//是否显示取消修改按钮
        },
        provinceAndCity: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        phone: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        id_card_number: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        department: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        job_name: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        idCardImg: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        bank: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        professionalProve: {
          content:false,
          button:true,
          isUpdate:false,
          cancleButton:true
        },
        professionalCover: {
          content: false,
          button: true,
          isUpdate: false,
          cancleButton:true
        },
      },
      status: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.itemScroll.initScroll();
    }, 500);
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.doctorInfo.province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
    bankCitys() {
      if (!this.doctorInfo.bank_province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.bank_province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  watch: {
    doctorInfo: {
      handler(newValue, oldValue) {
        this.$tools.setLocalCookie("doctorInfo", newValue);
        this.$tools.setLocalCookie("updateDoctorInfo", newValue);
        this.isDirty = true;
        // 处理值变化
      },
      immediate: false,
      deep: true,
    },
  },
  async created() {
    this.doctorId = this.$route.query.doctorId;
    this.status = this.$tools.getLocalCookie("status");
    if (this.status == 3) {
      this.loadData(this.doctorId);
    } else {
      this.doctorInfo = this.$tools.getLocalCookie("doctorInfo");
      if(this.doctorInfo.all_doctor_id){
        this.loadDcotroInfo(this.doctorInfo.all_doctor_id);
      }else{
        this.loadData(this.doctorInfo.id);
      }
      // this.updateDoctorInfo = this.$tools.getLocalCookie("updateDoctorInfo") || null;
      // if(this.doctorInfo.all_doctor_id == this.updateDoctorInfo.all_doctor_id){
      //   this.doctorInfo = this.updateDoctorInfo;
      // }else{
      //   this.loadDcotroInfo(this.doctorInfo.all_doctor_id);
      // }
    }
    this.config.citys = await this.$tools.getCityConfig();
  },
  methods: {
    changeProvince(key) {
      if (key == "bank") {
        this.doctorInfo.bank_city = "";
      } else {
        this.doctorInfo.city = "";
      }
    },
    loadDcotroInfo(id) {
      let url = this.$tools.getURL(this.$urls.doctor.getAllDoctorInfo, {
        id: id,
      });
      this.$axios.get(url).then((res) => {
        this.doctorInfo = { ...this.doctorInfo, ...res.data[0] };
        this.backInfo = JSON.parse(JSON.stringify(this.doctorInfo));
        this.doctorNameAndHospital = `${this.doctorInfo.name}-${this.doctorInfo.hospital}`;
        this.verifyDataComplete();
        this.getApplyAuditStatus();
        this.hideData();
        setTimeout(()=>{
          this.isDirty = false;
        },1000)
        // this.backInfo = { ...this.doctorInfo };
      });
    },
    loadData(doctorId) {
      let url = this.$tools.getURL(this.$urls.admin.doctor, {
        id: doctorId,
      });
      this.$axios.get(url).then((res) => {
        this.doctorInfo = { ...this.doctorInfo, ...res.data };
        this.backInfo = JSON.parse(JSON.stringify(this.doctorInfo));
        this.doctorNameAndHospital = `${this.doctorInfo.name}-${this.doctorInfo.hospital}`;
        this.verifyDataComplete();
        this.getApplyAuditStatus();
        this.hideData();
        setTimeout(() => {
          this.isDirty = false;
        }, 1000)
        // this.backInfo = { ...this.doctorInfo };
      });
    },
    //数据脱敏
    hideData(){
        for (let key in this.upload) {
          this.upload[key] = this.doctorInfo[key] ? 1 : 0;
          this.doctorInfo[key] = this.doctorInfo[key] || "";
          let tag = this.doctorInfo[key].replace(/./g, "*");
          let startLen = 0;
          let endLen = 0;
          switch (key){
            //手机号保留后4位
            case "phone":
              startLen = 0;
              endLen = 4
              break;
            //身份证号保留后6位
            case "id_card_number":
              startLen = 0;
              endLen = 6
              break;
            //银行卡号保留前3位后3位
            case "bank_number":
              startLen = 3;
              endLen = 3
              break;
            //其他的为链接,保留域名与文件名后3位:http://ioclassimedunion.com********921.png(前台不做处理,显示已上传)
            default:
              startLen = 3;
              endLen = 4
          }
          tag = tag.substr(startLen, tag.length-startLen-endLen);
          this.doctorInfo[key] = `${this.doctorInfo[key].substr(0, startLen)}${tag}${this.doctorInfo[key].substr(-endLen)}`;
        }
    },
    //验证医生数据是否完善，没有值的字段不显示修改按钮，但显示保存按钮
    verifyDataComplete(){
      let canotEmptySingleFields = ['name','hospital','phone','id_card_number','department','job_name'];
      for (const key of canotEmptySingleFields) {
        if(this.doctorInfo[key] == ""){
          this.modifyShow[key].content = true;
          this.modifyShow[key].isUpdate = true;
          this.modifyShow[key].button = false;
          this.modifyShow[key].cancleButton = false;
        }
      }
      if(!(this.doctorInfo.id_card_front && this.doctorInfo.id_card_reverse)){
          this.modifyShow['idCardImg'].content = true;
          this.modifyShow['idCardImg'].isUpdate = true;
          this.modifyShow['idCardImg'].button = false;
          this.modifyShow["idCardImg"].cancleButton = false;
      }
      if(!(this.doctorInfo.bank_name && this.doctorInfo.bank_province && this.doctorInfo.bank_city && this.doctorInfo.bank_name_point && this.doctorInfo.bank_number)){
          this.modifyShow['bank'].content = true;
          this.modifyShow['bank'].isUpdate = true;
          this.modifyShow['bank'].button = false;
          this.modifyShow["bank"].cancleButton = false;
      }
      if(this.doctorInfo.work_type==0&&!this.doctorInfo.doctor_work_id_card || this.doctorInfo.work_type==1&&!(this.doctorInfo.professional_person && this.doctorInfo.professional_panoramic)){
          this.modifyShow['professionalProve'].content = true;
          this.modifyShow['professionalProve'].isUpdate = true;
          this.modifyShow['professionalProve'].button = false;
          this.modifyShow["professionalProve"].cancleButton = false;
      }
    },
    //修改医生姓名、医院、科室、职称后要重新验证医生ID卡
    changeExamineStatus(){
      this.doctorInfo.examine_status = ""
    },
    receiveData(data) {
      this.cropperManager.url = "";
      if(!data.url) return;
      this.doctorInfo[this.cropperManager.key] = data.url;
      if (Object.prototype.hasOwnProperty.call(this.upload, this.cropperManager.key)){
        this.upload[this.cropperManager.key] = 0;
      }
      if (
        [
          "doctor_work_id_card",
          "professional_panoramic",
          "professional_person",
        ].includes(this.cropperManager.key)
      ) {
        this.doctorInfo.examine_status = ""
        this.loadJobName(data.url);
      }
    },
    getShowName(key, label) {
      return this.doctorInfo[key] ? "已上传" : label;
    },
    getKey(key) {
      if (this.config.types[key]) return this.config.types;
      if (this.config.merges.indexOf(key) > -1) return this.mergeFields;
      return this.doctorInfo;
    },
    loadJobName(image) {
      let url = this.$tools.getURL(this.$urls.admin.job, { url: image });
      this.$axios.get(url).then((res) => {
        if (res.job_name) this.doctorInfo.job_name = res.job_name;
      });
    },
    inputField(key){
      switch (key) {
        case "id_card_number":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D\W/g, "").replace("*", "");
          break;
        case "phone":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D/g, "");
          break;
        case "bank_number":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D/g, "");
          break;
        default:
          this.doctorInfo[key] = this.doctorInfo[key].replace("*", "");
          break;
      }
    },
    focusout(key) {
      console.log(key);
      // if (this.isHide(this.doctorInfo[key])) {
      //   this.doctorInfo[key] = this.backInfo[key];
      // }
    },
    async changeFile(event) {
      this.isloading = true;
      this.loadingText = "正在读取图片";
      let file = event.target.files[0];
      try {
        let fileInfo = await this.$tools.compressImage(file, true);
        this.isloading = false;
        this.loadingText = "";
        this.cropperManager.resetFile = false;
        this.cropperManager.url = fileInfo;
        this.$nextTick(() => {
            this.cropperManager.resetFile = true
            this.$nextTick(() => {
                this.$refs.imageCropper.setData(this.cropperManager.url);
            });
        })
      }catch{
        this.isloading = false;
        this.loadingText = "";
      }
      // let file = event.target.files[0];
      // if (!file) return;
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = async (item) => {
      //   this.cropperManager.url = item.target.result;
      //   this.cropperManager.resetFile = false;
      //   // console.log(this.cropperManager.url)
      //   this.$nextTick(() => {
      //     this.cropperManager.resetFile = true;
      //     // console.log(this.$refs.imageCropper);
      //     this.$refs.imageCropper.setData(this.cropperManager.url);
      //   });
      // };
    },
    changeWorkType() {
      console.log(11111)
      this.$refs.itemScroll.initScroll();
      console.log(this.doctorInfo)
      // this.doctorInfo.doctor_work_id_card = "";
      // this.doctorInfo.professional_panoramic = "";
      // this.doctorInfo.professional_person = "";
    },

    selectLocalFile() {
      this.$refs.selectfiles.click();
    },
    selectFile(key) {
      if (this.loading || !this.resetFile) return;
      if (key == "professional_cover") {
        this.config.cropper = {
          cropWidth: 225,
          cropHeight: 225,
          flexScale: [1, 1],
          MaxSize:2,
          isShowHeadimgCalibration: true,
        };
      } else {
        this.config.cropper = {
          cropWidth: 300,
          cropHeight: 189,
          flexScale: [8.56, 5.4],
          MaxSize:2,
          isShowHeadimgCalibration: false,
        };
      }
      this.cropperManager.key = key;
      this.selectLocalFile(key);
      // if (this.$tools.isLocal()) {
      //   this.selectLocalFile(key);
      // } else {
      //   this.loading = true;
      //   weixin
      //     .selectFile(1, 999999)
      //     .then((files) => {
      //       setTimeout(() => {
      //         this.loading = false;
      //       }, 1000);
      //       this.cropperManager.url = files[0];
      //       this.$nextTick(() => {
      //         this.$refs.imageCropper.setData(this.cropperManager.url);
      //       });
      //     })
      //     .catch((err) => {
      //       setTimeout(() => {
      //         this.loading = false;
      //       }, 1000);
      //     });
      // }
    },
    checkPhone() {
      this.doctorInfo.phone = this.doctorInfo.phone
        .replace(/\D/g, "")
        .substr(0, 11);
    },
    checkCard() {
      this.doctorInfo.id_card_number = this.doctorInfo.id_card_number
        .replace(/[^\dxX]/g, "")
        .substr(0, 18);
    },
    checkNumber() {
      this.doctorInfo.bank_number = this.doctorInfo.bank_number
        .replace(/\D/g, "")
        .substr(0, 30);
    },
    isHide(data) {
      return (data || "").includes("*");
    },
    checkData(key = '') {
      let keys = typeof key == "string" && key !== "" ? [key] : key
      if ((keys.length == 0  || keys.includes('name'))&&!this.doctorInfo.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if ((keys.length == 0  || keys.includes('phone'))&&!this.isHide(this.doctorInfo.phone)) {
        if (!this.doctorInfo.phone) {
          this.$tips.error({ text: "请输入手机号" });
          return false;
        }
        if (!/^1\d{10}$/g.test(this.doctorInfo.phone)) {
          this.$tips.error({ text: "请输入格式正确的手机号" });
          return false;
        }
      }
      if(keys.length == 0  || keys.includes('provinceAndCity')){
        if (!this.doctorInfo.province) {
          this.$tips.error({ text: "请选择医生省份" });
          return false;
        }
        if (!this.doctorInfo.city) {
          this.$tips.error({ text: "请选择医生城市" });
          return false;
        }
      }
      if ((keys.length == 0  || keys.includes('hospital'))&&!this.doctorInfo.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if ((keys.length == 0  || keys.includes('id_card_number'))&&!this.isHide(this.doctorInfo.id_card_number)) {
        if (!this.doctorInfo.id_card_number) {
          this.$tips.error({ text: "请输入身份证号" });
          return false;
        }
        let reg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (!reg.test(this.doctorInfo.id_card_number)) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
      }
      if(keys.length == 0  || keys.includes('bank')){
        if (!this.doctorInfo.bank_name) {
          this.$tips.error({ text: "请输入银行" });
          return false;
        }
        if (!this.doctorInfo.bank_province) {
          this.$tips.error({ text: "请选择银行省份" });
          return false;
        }
        if (!this.doctorInfo.bank_city) {
          this.$tips.error({ text: "请选择银行城市" });
          return false;
        }
        if (!this.doctorInfo.bank_name_point) {
          this.$tips.error({ text: "请输入开户银行" });
          return false;
        }
        if (!this.isHide(this.doctorInfo.bank_number)) {
          if (!this.doctorInfo.bank_number) {
            this.$tips.error({ text: "请输入银行账号" });
            return false;
          }
          if (
            !/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(
              this.doctorInfo.bank_number
            )
          ) {
            this.$tips.error({ text: "错误的银行账号" });
            return false;
          }
        }
      }
      if(keys.length == 0  || keys.includes("idCardImg")){
        if (!this.isHide(this.doctorInfo.id_card_front)) {
          if (!this.doctorInfo.id_card_front) {
            this.$tips.error({ text: "请上传身份证正面" });
            return false;
          }
        }
        if (!this.isHide(this.doctorInfo.id_card_reverse)) {
          if (!this.doctorInfo.id_card_reverse) {
            this.$tips.error({ text: "请上传身份证反面" });
            return false;
          }
        }
      }
      if ((keys.length == 0  || keys.includes('job_name'))&&!this.doctorInfo.job_name) {
        this.$tips.error({ text: "请选择职称" });
        return false;
      }
      if(keys.length == 0  || keys.includes('professionalProve')){
        if (this.doctorInfo.work_type == 0) {
          if (
            !this.doctorInfo.doctor_work_id_card &&
            !this.isHide(this.doctorInfo.doctor_work_id_card)
          ) {
            this.$tips.error({ text: "请上传医生ID卡" });
            return false;
          }
        }
        //没有医生ID卡才必填，有ID卡则选填
        if (this.doctorInfo.work_type == 1 && !this.doctorInfo.doctor_work_id_card) {
          if (
            !this.doctorInfo.professional_panoramic &&
            !this.isHide(this.doctorInfo.professional_panoramic)
          ) {
            this.$tips.error({ text: "请上传官网全景图" });
            return false;
          }
          if (
            !this.doctorInfo.professional_person &&
            !this.isHide(this.doctorInfo.professional_person)
          ) {
            this.$tips.error({ text: "请上传官网医生身份信息截图页" });
            return false;
          }
        }
      }
      if ((keys.length == 0  || keys.includes('department'))&&!this.doctorInfo.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if (keys.length == 0 &&!this.doctorInfo.professional_cover) {
        this.$tips.error({ text: "请上传简历图片" });
        return false;
      }
      return true;
    },
    async getDoctorID(){
      let url = this.$tools.getURL(this.$urls.admin.getDoctorID, { all_doctor_id: this.doctorInfo.all_doctor_id });
      let data = await this.$axios.get(url);
      return data;
    },
    //只更改某一单独字段的值
    async doCommitSingle(key){
      let keys = typeof key == "string" ? [key] : key
      return new Promise(async (resolve)=>{
        if (!this.checkData(keys) || this.isuploadingSingleField) {
          resolve(false);
          return;
        }
        let data = {
          all_doctor_id: this.doctorInfo.all_doctor_id,
          examine_status:this.doctorInfo.examine_status
        };
        if(!this.doctorInfo.id){
          let doctorIDData = await this.getDoctorID();
          if(doctorIDData.errcode){
            this.$tips.error({ text:"获取医生ID出错！" });
            resolve(false)
            return;
          }
          this.doctorInfo.id = doctorIDData.doctor_id;
          data.id = doctorIDData.doctor_id;
        }else{
          data.id = this.doctorInfo.id;
        }
        let dealKeys = Object.keys(this.config.modifyField).map(x=>keys.includes(x) ? this.config.modifyField[x] : [])
        for (const doctorKey of dealKeys.flat()) {
          if (doctorKey!== "work_type"&&this.isHide(this.doctorInfo[doctorKey])) {
            data[doctorKey] = this.backInfo[doctorKey];
          }else{
            data[doctorKey] = this.doctorInfo[doctorKey];
            this.backInfo[doctorKey] = this.doctorInfo[doctorKey];//提交时把缓存的值改为最新
          }
        }
        this.isuploadingSingleField = true;
        try{
          let res = await this.$axios.patch(this.$urls.admin.updateDoctorSingle, { values: data },{});
          // console.log(res,res.code)
          this.isuploadingSingleField = false;
          if(res.code == 400){
            if(res.key == "professionalProve"){
              this.$confirm(res.msg,"提示",{
                confirmButtonText: "我上传的信息齐全，申请人工审核",
                cancelButtonText: "点击补充",
                center: true,
                type: "warning",
                cancelButtonClass:"docterdetail_cancelButtonClass",
                customClass: "docterdetail_confirm_customClass",
              }).then(() => {
                this.applyAudit();
              }).catch(() => {});
            }else{
              let text = res.msg || this.commitSingleField[res.key];
              this.$tips.error({ text: text });
              this.uploadingSingleFieldErr = true;
            }
            resolve(false)
            return;
          }
          keys.forEach(key => {
            this.modifyShow[key].content = false;
            this.modifyShow[key].isUpdate = false;
            //提交一次之后修改按钮和取消修改按钮需要按正常显示(逻辑等同于进入页面时对应字段有值)
            this.modifyShow[key].button = true;
            this.modifyShow[key].cancleButton = true;
          });
          this.$refs.itemScroll.initScroll();
          resolve(true);
        }catch(err){
          this.isuploadingSingleField = false;
          console.log(err)
          // this.$tips.error({ text: "修改医生信息出错" });
          resolve(false);
        }
      })
      // this.$axios.patch(this.$urls.admin.updateDoctorSingle, {values:data}).then(() => {
      //     setTimeout(() => {
      //       this.isloading = false;
      //       this.modifyShow[key].content = false;
      //       this.modifyShow[key].isUpdate = false;
      //       this.$refs.itemScroll.initScroll();
      //     }, 1000);
      //     this.$tips.success({ text: "修改成功" });
      //   }).catch((err) => {
      //     console.log(err);
      //     setTimeout(() => {
      //       this.isloading = false;
      //     }, 1000);
      //   });
    },
    //申请人工审核
    applyAudit(){
      this.$axios.post(this.$urls.admin.applyAudit,{doctor_id:this.doctorInfo.id}).then(res=>{
        this.$tips.success({text:"申请人工审核成功"});
        this.getApplyAuditStatus();
      }).catch(err=>{
        this.$tips.error({text:"申请人工审核失败"});
        console.log(err)
      })
    },
    //获取人工审核状态
    getApplyAuditStatus(){
      let url = this.$tools.getURL(this.$urls.admin.examineStatus,{doctor_id:this.doctorInfo.id,all_doctor_id:this.doctorInfo.all_doctor_id});
      this.$axios.get(url).then(res=>{
        this.doctorInfo.examine_status = res.examine_status;
        this.doctorInfo.reason = res.reason;
        this.$refs.itemScroll.initScroll();
      }).catch(err=>{
        console.log(err)
      })
    },
    async doCommit() {
        // console.log("this.isDirty=====", this.isDirty)
        // if (!this.isDirty) {
        //   if (this.status == 1) {
        //     this.$tools.setLocalCookie("status", 2);
        //   } else if (this.status == 3) {
        //     this.$tools.setLocalCookie("doctorInfo", {});
        //   }
        //   this.$router.back();
        //   return
        // }
        if (!this.checkData() || this.isloading) return;
        this.isloading = true;
        for (let key in this.upload) {
          //如果包含*号，代表该脱敏信息未被修改，需返还成未脱敏的信息
          if (this.isHide(this.doctorInfo[key])) {
            this.doctorInfo[key] = this.backInfo[key];
          }
        }
        this.uploadingSingleFieldErr = false;
        let keys = []
        for (const key of Object.keys(this.commitSingleField)) {
          //医生ID卡和官网信息人工审核中或审核通过则直接跳过
          if(key == "professionalProve" && (this.doctorInfo.examine_status === 0 || this.doctorInfo.examine_status === 1)) continue;
          keys.push(key)

        }
        if(keys.length > 0){
          let res = await this.doCommitSingle(keys);
          if(!res){
            this.isloading = false;
            this.hideData();
            return;
          }
        }
        this.isloading = false;
        if(this.uploadingSingleFieldErr) {
          this.hideData();
          return;
        }
        if (this.status == 1) {
          this.$tools.setLocalCookie("status", 2);
        } else if (this.status == 3) {
          this.$tools.setLocalCookie("doctorInfo", {});
        }
        this.$router.back();
      // this.$axios.patch(this.$urls.admin.doctor, {
      //     ...this.doctorInfo,
      //   }).then((res) => {
          // setTimeout(() => {
          //   this.isloading = false;
          // }, 1000);
          // if (this.status == 1) {
          //   this.$tools.setLocalCookie("status", 2);
          // } else if (this.status == 3) {
          //   this.$tools.setLocalCookie("doctorInfo", {});
          // }

          // this.$router.back();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setTimeout(() => {
      //       this.isloading = false;
      //     }, 1000);
      //   });
    },
    //取消修改
    cancleUpdate(key){
      this.modifyShow[key].content = false;
      this.modifyShow[key].isUpdate = false;
      for (const doctorKey of this.config.modifyField[key]) {
        this.doctorInfo[doctorKey] = this.cacheDoctorInfo[doctorKey];
      }
      this.$refs.itemScroll.initScroll();
    },
    modify(key) {
      for (const doctorKey of this.config.modifyField[key]) {
        this.cacheDoctorInfo[doctorKey] = this.doctorInfo[doctorKey];
      }
      this.modifyShow[key].content = !this.modifyShow[key].content;
      this.modifyShow[key].isUpdate = true;
      if (["provinceAndCity","phone", "id_card_number", "department", "job_name","idCardImg","bank"].includes(key)) {
        for (const doctorKey of this.config.modifyField[key]) {
          if(doctorKey == "work_type") {
            this.doctorInfo[doctorKey] = 0;
          }else{
            this.doctorInfo[doctorKey] = "";
          }
        }
        // this.doctorInfo[key] = "";
      }
      this.$refs.itemScroll.initScroll();
    },
  },
  unmounted() {
    if (this.status == 3) {
      this.$tools.setLocalCookie("doctorInfo", {});
      this.$tools.setLocalCookie("updateDoctorInfo", {});
    }
  },
};
</script>

<style>
.doctor-detail-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 4vw;
  background: #f5f5f5;
  overflow: hidden;
}
.doctor-detail-main {
  height: auto;
}
.doctor-detail-block {
  margin-bottom: 4vw;
}
.doctor-detail-block .el-button {
  width: 100%;
}
.doctor-detail-key {
  color: #6f6f6f;
}
.doctor-detail-key span {
  color: red;
}
.doctor-detail-key {
}
.doctor-detail-value {
  margin-top: 2vw;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
  color: #6a6266;
}
.doctor-detail-name .el-input.is-disabled .el-input__inner {
  background: #ccc;
  color: #000;
}
.doctor-detail-name {
  /* padding:0; */
  background: #ccc;
  border: 1px solid #ccc;
}
.doctor-detail-image-block {
  width: 85.6vw;
  height: 54vw;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}
.doctor-detail-image-block img {
  width: 85.6vw;
  height: 54vw;
}
.doctor-detail-image-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.doctor-detail-image-select i {
  font-size: 8vw;
  line-height: 12vw;
}
.doctor-detail-container .customButton_container {
  color: #fff;
}
.professional_cover_container {
  display: flex;
}
.professional_cover {
  width: 32vw;
  height: 32vw;
  background-color: #c9c5c5;
}
.professional_cover img {
  width: 100%;
  height: 100%;
}
.professional_cover_tips {
  margin-left: 4vw;
  flex: 1 0 0;
  color: #a40250;
  font-size: 8vw;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.docterdetail_button {
  flex: 18vw 0 0;
  padding: 1vw 4vw;
  border-radius: 2vw;
  text-align: center;
  border: 1px solid #008f3d;
  color: #000;
}
.doctor-detail-container .el-input.is-disabled .el-input__inner {
  background: #fff;
}
.doctor-detail-value {
  color: #c8cbd2;
}
.update_button_group{
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}
.cancle_button{
  margin-right: 4vw;
}
.update_button{
    padding: 1vw 4vw;
    border-radius: 2vw;
    text-align: center;
    border: 1px solid #008f3d;
    color: #000;
}
.docterdetail_confirm_customClass {
  width: 90%;
}
.docterdetail_confirm_customClass .el-message-box__message,.docterdetail_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
.docterdetail_confirm_customClass .el-message-box__btns button{
  width: 100%;
}
.docterdetail_confirm_customClass .el-message-box__btns button:nth-child(2){
  margin-top: 4vw;
  margin-left: 0;
}
.docterdetail_cancelButtonClass{
  background-color:#008f3d;
  color: #fff;
}
</style>
